// Here you can add other styles
.table-container {
    height: calc(100vh - 180px);
    overflow-y: auto;
  }
  
  /* Sticky table header */
  .table-container thead {
    position: sticky;
    top: 0;
    z-index: 1020;
    background-color: #f8f9fa; /* Light background */
  }